<template>
  <div class="div-clas mb-5" style="margin-top:-5rem !important;">
      <div class="bg-25k">
        <div class="container-fluid margin-text-titulo-25k">
          <div class="row">
            <img class="img2-header-25k-movil" style="margin-top:7rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            <div class="col-md-5 offset-md-1">
              <h1 class="text-titulo-header-25k animate__animated  animate__bounceInDown animate__fast "><b>THE 25K <br> CLUB </b></h1>
              <div class="tamaño-div-25k"><p class="text-header-25k">Te ayudamos a que tu tienda online comience a vender 25 mil dolares (500 mil pesos mxn) al mes en tan solo 4 meses. Te garantizamos crecer tus ventas en mínimo 50% desde el primer mes. </p></div>
              <p class="text-25k">Valido para empresas vendiendo más de 5 mil dólares (100 mil pesos mxn) al mes. Si vendes menos, consulta nuestro paquete emprendedor <span class="line-text-25k"><b>aquí</b></span></p>
            </div>
            <div class="col-md-6">
              <img class="img-header-25k mb-5" src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 offset-md-1 text-align-l-25k">
            <img class="img-nosotros-embudo-movil" src="../assets/The_25k_Club/embudo_ventas.png" alt="">
              <h1 class="text-titulo-25k"><b>Lo que haremos </b></h1>
              <p class="p-25k mt-4">Creamos una estrategia publicitaria y usamos software con inteligencia artificial para optimizar el gasto y maximizar las ganancias de tus anuncios. También automatizamos tu ecommerce para que venda por si solo y se aumente la conversión a ventas.</p>
          </div>
          <div class="col-md-4 offset-md-1">
            <div class=""><img class="img-nosotros-embudo" src="../assets/The_25k_Club/embudo_ventas.png" alt=""></div>
          </div>
        </div>
      </div>
    <div class="container-fluid mt-5 mb-5">
        <h1 class="text-titulo-25k2"><b> ¿Que incluye este paquete?</b></h1>
    </div>

    <div class="container-fluid ">
      <!-- bloque 1 -->
      <div class="row">
        <div class="col-md-6">
          <div class="div1-25k padre z-cuadros-25k row" data-aos="zoom-in-up">
            <div class="col-md-10 offset-md-1">
              <h1 class="text-cuadros-25k mb-3"> <b>1. SMS Masivos</b></h1>
              <p class="text-25k-cuadros">Enviamos SMS masivos a los clientes que ya te comprarom para fidelizar al cliente y seguir recibiendo compras de este.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div2-25k padre z-cuadros-25k" data-aos="zoom-in-up"><div class="col-md-10 offset-md-1">
            <h1 class="text-cuadros-25k mb-3 mt-5"> <b>2. Whatsapp Marketing</b></h1>
              <p class="text-25k-cuadros">Hoy en dia solo el 30% ve su email, pero todos vemos Whatsapp. Cuando hay carritos olvidados necesitamos enviar un whatsapp con un código de descuento. Y posterior a la compra, enviar un código o promoción que incentive al cliente a seguir comprando.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ">
      <!-- bloque 2 -->
      <div class="row">
        <div class="col-md-6">
          <div class="div3-25k padre z-cuadros-25k row" data-aos="zoom-in-up">
            <div class="col-md-10 offset-md-1">
              <h1 class="text-cuadros-25k mb-3"> <b>3. Sitio web</b></h1>
              <p class="text-25k-cuadros">El proceso de compra en tu sitio web tiene que ser muy fácil y amigable para el cliente final.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div4-25k padre z-cuadros-25k" data-aos="zoom-in-up"><div class="col-md-10 offset-md-1">
            <h1 class="text-cuadros-25k mb-3"> <b>4.Redes sociales</b></h1>
              <p class="text-25k-cuadros">Necesitas pucblicar constantemente en tus redes sociales para mantener una audiencia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div class="container-fluid ">
      <!-- bloque 3 -->
      <div class="row">
        <div class="col-md-6">
          <div class="div5-25k padre z-cuadros-25k row" data-aos="zoom-in-up">
            <div class="col-md-10 offset-md-1">
              <h1 class="text-cuadros-25k mb-3"> <b>5. Campañas de ventas</b></h1>
              <p class="text-25k-cuadros">Necesitas campañas de venta en Facebook y Google Ads segmentadas a tu público objetivo.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="div6-25k padre z-cuadros-25k" data-aos="zoom-in-up">
            <div class="col-md-10 offset-md-1">
              <h1 class="text-cuadros-25k mb-3"> <b>6. Chatbot de ventas</b></h1>
              <p class="text-25k-cuadros">No puedes tardar más de un minuto en responder a tu cliente, para eso necesitas un Chatbot en Whatsapp.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- hasta aqui -->
    <div class="container-fluid">
      <h1 class="text-titulo-25k-div-final"><b>¿Qué necesitamos para arrancar?</b></h1>
      <img class="img-fluid fondo-web" src="../assets/The_25k_Club/fondo_cohete.png" alt="">
      <img class="img-fluid fondo-movil" src="../assets/The_25k_Club/fondo_cohete_movil.png" alt="">
    </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.img-header-25k{
    display: inline;
  }
  .img2-header-25k-movil{
    display: none;
    
  }
.fondo-web{
 margin-bottom: 10%;
}
.fondo-movil{
  display: none;
}
.f-25k{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-25k { 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div1-25k{
  background-image: url("../assets/Inicio/creacion_pagina_web.png");
   width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

}
.div2-25k{
  background-image: url("../assets/Inicio/desarrollo_plataformas.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div3-25k{
  background-image: url("../assets/The_25k_Club/sitio_web.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div4-25k{
  background-image: url("../assets/The_25k_Club/redes_sociales.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div5-25k{
  background-image: url("../assets/Inicio/campanas_fb_ga.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.div6-25k{
  background-image: url("../assets/The_25k_Club/sms_masivos.png");
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}
.img-nosotros-embudo-movil{
  display: none;
}
.img-nosotros-embudo{
  display: inline;
}


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
.fondo-web{
  display: none;
}
.fondo-movil{
  display: inline;
  margin-right: 0%;
}

.z-cuadros-25k{
  height:400px !important;
  margin-top:5%;
  margin-left: 1px;
  
}

.text-cuadros-25k{
  color:white;
  display:block; 
  margin: 0 auto; 
  font-size:1.6rem !important;
}
.img-header-25k{
    display: none;
  }
  .img2-header-25k-movil{
    display: inline;
    width: 40%;
    margin-top: 8%;
    margin-bottom: 3%;
    margin-left: 30%;
  }
  .text-titulo-header-25k{
    color: rgb(255, 255, 255);
    font-size: 47px !important;
    width: 100%;
    text-align: center;
    
  }
  .text-header-25k{
  color: rgb(255, 255, 255);
    font-size: 20px !important;
    text-align: justify;
    padding: 5%;
    margin-top: 0px;
}
.text-25k{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
}
.text-titulo-25k{
  font-size: 1.6rem !important;
  color: #12045e;
  margin-top: 10%;
  text-align: center;
}
.p-25k{
  font-size: 1.2rem !important;
    width: 100%;
}
.img-nosotros-embudo-movil{
  display: inline;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 1%;
    margin-left: 5%;
}
.img-nosotros-embudo{
  display: none;
  
}
.text-titulo-25k2{
  font-size: 1.6rem !important;
  color: #12045e;
  margin-top: 10%;
  text-align: center;
}
.text-25k-cuadros{
  color:white;
  font-size: 1.2rem !important;
  text-align:justify;
  padding: 3%;
}
.text-titulo-25k-div-final {
  font-size: 1.6rem !important;
}
}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-25k, .img-header-25k{
    margin-top: 3rem !important;
    
  }
  
}

</style>